import React from "react"

import { Box, Grid, Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import ContactForm from "../components/contact-form"

const ContactPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Contact` }]}>
      <Helmet>
        <title>Contact - Todocards.nl</title>

        <meta
          name="description"
          content="Heb je een vraag of opmerking? Neem dan contact met ons op via het contactformulier. Wij zullen dan zo snel mogelijk reageren."
        />
      </Helmet>

      <Grid container spacing={4}>
        <Grid item sm={9}>
          <Typography variant="h1" gutterBottom>
            Contact
          </Typography>

          <Box mb={2}>
            <Typography paragraph={true}>
              Neem contact met ons op door het onderstaande formulier in te
              vullen. Wij zullen dan zo snel mogelijk reageren. Heb je een vraag
              of opmerking over een bestelling? Vermeld dan ook het
              bestelnummer, zo kunnen we jou beter van dienst zijn.
            </Typography>
          </Box>

          <ContactForm />
        </Grid>

        <Grid item sm={3}>
          <Typography variant="h2" gutterBottom>
            Onze gegevens
          </Typography>

          <Typography paragraph={true}>
            Todocards.nl
            <br />
            Mr. Tripkade 18
            <br />
            3571 SX Utrecht
            <br />
            Nederland
          </Typography>

          <Typography>
            E-mail: info@todocards.nl
            <br />
            KvK: 30221205
            <br />
            BTW: NL001179039B37
          </Typography>
        </Grid>
      </Grid>
    </MainShell>
  )
}

export default ContactPage
